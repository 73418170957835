import './circle-item_v2-1.scss'

const CircleItem = ({
  itemURL,
  itemIcon,
  itemLabel,
  labelFontSize = '13pt',
  colorStyle,
}) => {
  return (
    <a
      aria-label={`Link to more information about ${itemLabel}`}
      className='circle-link'
      target='_blank'
      rel='noreferrer'
      href={itemURL}
      style={colorStyle}
    >
      <div className='circle-link-wrapper'>
        {itemIcon}
        <svg
          aria-hidden='true'
          focusable='false'
          className='item-label-svg'
          viewBox='0 0 100 100'
          xmlns='http://www.w3.org/2000/svg'
        >
          <text
            className='item-label'
            x='50%'
            y='50%'
            textAnchor='middle'
            dominantBaseline='central'
            fontSize={labelFontSize}
          >
            {itemLabel}
          </text>
        </svg>
        <svg
          aria-hidden='true'
          focusable='false'
          className='item-label-svg-mobile'
          viewBox='0 0 100 50'
          xmlns='http://www.w3.org/2000/svg'
        >
          <text
            className='item-label'
            x='50%'
            y='50%'
            textAnchor='middle'
            dominantBaseline='central'
            fontSize={labelFontSize}
          >
            {itemLabel}
          </text>
        </svg>
      </div>
    </a>
  )
}

export default CircleItem
