import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BlenderIcon from '../SVGs/blender-icon_v2-1'
import CSharpIcon from '../SVGs/csharp-icon_v2-1'
import { faUnity } from '@fortawesome/free-brands-svg-icons'

export const bakingSimTechsData = [
  {
    url: 'https://learn.microsoft.com/en-us/dotnet/csharp/tour-of-csharp/',
    icon: <CSharpIcon svgClass='item-icon' />,
    label: 'C#',
    labelFontSize: '16pt',
  },
  {
    url: 'https://en.wikipedia.org/wiki/Unity_(game_engine)',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faUnity}
      />
    ),
    label: 'Unity',
    labelFontSize: '16pt',
  },
  {
    url: 'https://www.blender.org/about/',
    icon: <BlenderIcon svgClass='item-icon' />,
    label: 'Blender',
    labelFontSize: '16pt',
  },
]
