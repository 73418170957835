import './closed-style_v2-1.scss'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

const ClosedRLink = ({
  linkClass = '',
  linkPath = '/',
  isDownload = false,
  downloadName = '',
  targetStyle = '_self',
  clickFunction = null,
  linkContent = '',
  borderColor = 'red',
  bgColor = 'white',
  ariaLabel = '',
}) => {
  const MotionLink = motion(Link)

  const draw = {
    hidden: { pathLength: 0 },
    visible: {
      pathLength: 1,
    },
  }

  return (
    <MotionLink
      aria-label={ariaLabel}
      className={`animated-closed ${linkClass}`}
      to={linkPath}
      download={isDownload ? downloadName : false}
      target={targetStyle}
      onClick={clickFunction}
      initial='hidden'
      whileHover='visible'
      style={{
        '--borderColor': `${borderColor}`,
        '--bgColor': `${bgColor}`,
      }}
    >
      <span className='content-wrapper'>
        <span className='content-span'>
          <p
            className='content-text'
            title={linkContent}
          ></p>
        </span>
      </span>
      <span className='border-svg-container'>
        <svg
          aria-hidden='true'
          focusable='false'
          width='100%'
          height='100%'
          viewBox='0 0 62 22'
          preserveAspectRatio='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M 50 1 H 12 C -3 2 -3 20 12 21 h 38 c 15 -1 15 -19 0 -20 l -0.25 0 Z'
            className='border-bg'
          />
          <motion.path
            d='M 50 1 H 12 C -3 2 -3 20 12 21 h 38 c 15 -1 15 -19 0 -20 l -0.25 0 Z'
            className='border-line'
            variants={draw}
            transition={{
              type: 'spring',
              duration: 0.75,
              bounce: 0,
            }}
          />
        </svg>
      </span>
    </MotionLink>
  )
}

export default ClosedRLink
