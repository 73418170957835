import './svg-button_v2-1.scss'

const SVGALink = ({
  buttonURL,
  buttonIcon,
  buttonLabel,
  tx,
  ty,
  rdeg,
  scale,
}) => {
  const transformStyle = {
    '--tx': `${tx}rem`,
    '--ty': `${ty}rem`,
    '--rdeg': `${rdeg}deg`,
    '--scale': `${scale}`,
  }

  return (
    <a
      aria-label={`Link to ${buttonLabel}`}
      className='button-link'
      target='_blank'
      rel='noreferrer'
      href={buttonURL}
      style={transformStyle}
    >
      {buttonIcon}
      <span className='button-label'>{buttonLabel}</span>
    </a>
  )
}

export default SVGALink
