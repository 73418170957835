import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MatplotlibIcon from '../SVGs/matplotlib-icon_v2-1'
import OpenCVIcon from '../SVGs/opencv-icon_v2-1'
import NumpyIcon from '../SVGs/numpy-icon_v2-1'
import TKIcon from '../SVGs/tk-icon_v2-1'
import { faPython } from '@fortawesome/free-brands-svg-icons'

export const faceSwapTechsData = [
  {
    url: 'https://matplotlib.org/',
    icon: <MatplotlibIcon svgClass='item-icon' />,
    label: 'Matplotlib',
    labelFontSize: '14pt',
  },
  {
    url: 'https://opencv.org/about/',
    icon: <OpenCVIcon svgClass='item-icon' />,
    label: 'OpenCV',
    labelFontSize: '16pt',
  },
  {
    url: 'https://www.python.org/doc/essays/blurb/',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faPython}
      />
    ),
    label: 'Python',
    labelFontSize: '16pt',
  },
  {
    url: 'https://numpy.org/doc/stable/user/whatisnumpy.html',
    icon: <NumpyIcon svgClass='item-icon' />,
    label: 'NumPy',
    labelFontSize: '16pt',
  },
  {
    url: 'https://docs.python.org/3/library/tkinter.html',
    icon: <TKIcon svgClass='item-icon' />,
    label: 'Tkinter',
    labelFontSize: '16pt',
  },
]
