// SVG Created By https://iconduck.com/icons/20151/chartjs - License: ISC https://en.wikipedia.org/wiki/ISC_license

const ChartJSIcon = ({ svgClass }) => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      className={svgClass}
      width='100%'
      height='100%'
      viewBox='0 0 444 512'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='m93.7733383 383.3009949-37.588623-23.4613342c7.174324-23.2473755 66.0021667-128.7017517 101.1509018-125.7351532-20.3777314 53.3128814-31.7953263 100.8101043-63.5622788 149.1964874zm73.8983383-125.874878c-16.8242798 50.2639771-35.684906 96.8053589-59.4300461 134.417511l113.7698746 65.8455811 179.7962646-103.5905457v-96.0539246c-17.4086304 1.8987732-28.0395508 11.7143555-33.8562622 27.6247864-8.9650574 24.5220642-45.0889282 53.5266418-74.2076721 14.4343262-46.2934722 73.8170471-89.6911926 61.1315613-126.0721588-42.6777344zm-15.9754943-37.500595c-29.7174606-63.5350342-86.1285858-64.3585663-110.2877045 30.382431v99.3651428c16.7517624-30.0823364 69.4946976-126.0295868 110.2877045-129.7475738zm241.5510711 23.2843627c-30.1818542-22.6328278-75.2782288 4.9260864-92.6665344 44.7718048 16.2728271 26.8457031 32.6260071 21.3126831 46.4331665 8.3146973 11.1155701-10.4641418 14.9871826-42.3819732 46.2333679-53.0865021zm-131.3187256 1.7409974c-28.1663666-53.9352264-52.0266418-95.9476776-82.7436218-17.2023468 25.4464264 19.9443512 55.9373474 40.1491699 82.7436218 17.2023468zm24.1966858 44.3537139c-6.0588989-9.2062378-12.0392761-20.2755737-17.6111145-31.4589539-26.7772369 21.1087952-60.7082062 10.8710022-94.6219788-16.6014252 20.7817535 53.4997101 37.0730133 83.2093658 53.3447723 91.2517548 18.3864442 9.0875245 43.2043305-11.9700317 58.888321-43.1913757zm-255.9030227-144.027359v221.4455109l191.7778091 110.7227783 191.7778015-110.7227783v-221.4455109l-191.7778015-110.722763zm413.7778091-17.2772369v256l-222 128-222-128v-256l222-128zm-42.1922302 96.8550262c-33.024231-49.8251343-60.827301-52.7593842-81.1879883-38.3804016-18.5274658 13.0841827-31.1453552 44.2569122-41.2397766 60.6603699 4.4847412 10.0633698 8.7099609 15.6719208 13.6134949 24.9513702 23.6547546-45.0139923 67.62146-71.4070129 108.81427-40.747879z' />
    </svg>
  )
}

export default ChartJSIcon
