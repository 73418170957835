import { useRef } from 'react'
import './contact-form_v2-1.scss'
import emailjs from '@emailjs/browser'
import SVGButton from '../AnimatedButtonsLinks/svg-button_v2-1'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const ContactForm = () => {
  const formRef = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_contact_test',
        'template_contact_test',
        formRef.current,
        'pn4E96tOWLy0-uGqw'
      )
      .then(
        () => {
          alert('Message successfully sent!')
          window.location.reload(false)
        },
        () => {
          alert('Failed to send message. Please try again.')
        }
      )
  }

  return (
    <form
      className='contact-form'
      ref={formRef}
      onSubmit={sendEmail}
    >
      <ul className='contact-list'>
        <li>
          <input
            aria-label='Enter your name'
            aria-required='true'
            type='text'
            name='user-name'
            placeholder='Name*'
            required
          />
        </li>
        <li>
          <input
            aria-label='Enter your email'
            aria-required='true'
            type='email'
            name='user-email'
            placeholder='Email*'
            required
          />
        </li>
        <li>
          <input
            aria-label='Enter your message subject'
            aria-required='false'
            type='text'
            name='user-subject'
            placeholder='Subject'
          />
        </li>
        <li>
          <textarea
            aria-label='Enter your message'
            aria-required='true'
            name='user-message'
            placeholder='Message*'
            required
          />
        </li>
        <li>
          <div className='submit-button-container'>
            <SVGButton
              buttonType='submit'
              buttonIcon={
                <FontAwesomeIcon
                  aria-hidden='true'
                  focusable='false'
                  className='button-icon'
                  icon={faEnvelope}
                />
              }
              buttonLabel='SEND'
              tx={0}
              ty={0}
              rdeg={60}
              scale={25}
            />
          </div>
        </li>
      </ul>
    </form>
  )
}

export default ContactForm
