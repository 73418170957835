import React from 'react'
import { useRef } from 'react'

type Props = {
  inputText: string | string[]
  el?: keyof JSX.IntrinsicElements
  className?: string
  lettersClass?: string
}

const AnimatedText = ({
  inputText,
  el: Wrapper = 'p',
  className = '',
  lettersClass = '',
}: Props) => {
  const textArray = Array.isArray(inputText) ? inputText : [inputText]
  const ref = useRef(null)

  return (
    <Wrapper className={className}>
      <span className='sr-only'>{textArray.join(' ')}</span>
      <span
        ref={ref}
        aria-hidden
      >
        {textArray.map((line, lineIndex) => (
          <span key={`${line}-${lineIndex}`}>
            {line.split(' ').map((word, wordIndex) => (
              <span key={`${word}-${wordIndex}`}>
                {word.split('').map((char, charIndex) => (
                  <span
                    className={lettersClass}
                    style={{ display: 'inline-block' }}
                    key={`${char}-${charIndex}`}
                  >
                    {char}
                  </span>
                ))}
                <span>&nbsp;</span>
              </span>
            ))}
          </span>
        ))}
      </span>
    </Wrapper>
  )
}

export default AnimatedText
