import './portfolio-page_v2-1.scss'
import { useOutletContext } from 'react-router-dom'
import CircleList from '../../AddOns/CircleList/circle-list_v2-1'
import SVGALink from '../../AddOns/AnimatedButtonsLinks/svg-alink_v2-1'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PortfolioPage = ({
  projectTitle,
  teamInfo,
  roleInfo,
  dateCompleted,
  paragraph1,
  paragraph2,
  paragraph3,
  image1,
  image2,
  image3,
  image1Mini,
  image2Mini,
  image3Mini,
  image1Alt,
  image2Alt,
  image3Alt,
  image1Fig,
  image2Fig,
  image3Fig,
  techsData,
  circleMod,
  circleClass = '',
  repoPath,
}) => {
  // Context for image modal
  const handleImageClick = useOutletContext()

  return (
    <div className='anchor page portfolio-page'>
      <div className='container section-1-container'>
        <div className='content section-1-content'>
          <h1 className='title project-title'>{projectTitle}</h1>
          <div className='content-grid section-1-grid'>
            <div className='content-grid-left section-1-text-container no-transition'>
              <div className='project-info-wrapper'>
                <div className='project-info-item'>
                  <p className='project-info-label'>Teammate(s):</p>
                  <p className='project-info-text'>{teamInfo}</p>
                </div>
                <div className='project-info-item'>
                  <p className='project-info-label'>Role:</p>
                  <p className='project-info-text'>{roleInfo}</p>
                </div>
                <div className='project-info-item'>
                  <p className='project-info-label'>Completed:</p>
                  <p className='project-info-text'>{dateCompleted}</p>
                </div>
              </div>
              <div className='text-overflow-wrapper no-transition'>
                <p className='project-desc-paragraph'>{paragraph1}</p>
              </div>
            </div>
            <div className='content-grid-right section-1-image-container'>
              <div className='project-image-shadow-wrapper section-1-shadow'>
                <div className='project-image-wrapper'>
                  <img
                    className='project-image'
                    src={image1Mini}
                    alt={image1Alt}
                    onClick={(e) => handleImageClick(e, image1)}
                  ></img>
                </div>
              </div>
              <p className='project-image-label'>{`Figure 1: ${image1Fig}.`}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='container section-2-container'>
        <div className='content section-2-content'>
          <div className='content-grid section-2-grid'>
            <div className='content-grid-left section-2-image-container'>
              <div className='project-image-shadow-wrapper section-2-shadow'>
                <div className='project-image-wrapper'>
                  <img
                    className='project-image'
                    src={image2Mini}
                    alt={image2Alt}
                    onClick={(e) => handleImageClick(e, image2)}
                  ></img>
                </div>
              </div>
              <p className='project-image-label section-2-label'>{`Figure 2: ${image2Fig}.`}</p>
            </div>
            <div className='content-grid-right section-2-text-container no-transition'>
              <div className='text-overflow-wrapper'>
                <p className='project-desc-paragraph'>{paragraph2}</p>
                <p className='project-desc-paragraph section-2-paragraph'>
                  {paragraph3}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container section-3-container'>
        <div className='content section-3-content'>
          <div className='content-grid section-3-grid'>
            <div className='content-grid-left section-3-circle-container'>
              <h3 className='section-3-title'>Technologies</h3>
              <div className={`techs-list ${circleClass}`}>
                <div className='circle-center-container'>
                  <SVGALink
                    buttonURL={repoPath}
                    buttonIcon={
                      <FontAwesomeIcon
                        className='button-icon'
                        icon={faBook}
                      />
                    }
                    buttonLabel='Documentation'
                    tx={15}
                    ty={0}
                    rdeg={60}
                    scale={27}
                  />
                </div>
                <CircleList
                  listData={techsData}
                  sizeModifier={circleMod}
                  iconColor='#734bff'
                  labelColor='#382086'
                />
              </div>
            </div>
            <div className='content-grid-right section-3-image-container'>
              <div className='project-image-shadow-wrapper section-3-shadow'>
                <div className='project-image-wrapper'>
                  <img
                    className='project-image'
                    src={image3Mini}
                    alt={image3Alt}
                    onClick={(e) => handleImageClick(e, image3)}
                  ></img>
                </div>
              </div>
              <p className='project-image-label'>{`Figure 3: ${image3Fig}.`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PortfolioPage
