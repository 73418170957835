import './layout_v2-1.scss'
import Navbar from '../Navbar/navbar_v2-1'
import { Outlet } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import { useEffect, useState } from 'react'
import { ImageModal } from '../AddOns/ImageModal/image-modal_v2-1'

const Layout = () => {
  const theme_pref = window.matchMedia('(prefers-color-scheme: dark)').matches
  const [isDark, setIsDark] = useLocalStorage('isDark', theme_pref)
  const [stopScroll, setStopScroll] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalSource, setModalSource] = useState('')
  const [modalText, setModalText] = useState('')

  function changeModalOpen(newModalOpen) {
    setIsModalOpen(newModalOpen)
  }

  function handleModalClick(e, newSource) {
    setIsModalOpen(true)
    setModalSource(newSource)
    setModalText(e.target.getAttribute('alt'))
  }

  function toggleIsDark() {
    setIsDark((prevState) => !prevState)
  }

  function changeStopScroll(newScroll) {
    setStopScroll(newScroll)
  }

  useEffect(() => {
    document.getElementById('body').className = `${
      stopScroll ? 'stop-scroll' : ''
    }`
  }, [stopScroll])

  return (
    <div
      className='app'
      data-theme={isDark ? 'dark' : 'light'}
    >
      <Navbar
        isDark={isDark}
        toggleIsDark={toggleIsDark}
        changeStopScroll={changeStopScroll}
        changeModalOpen={changeModalOpen}
      />
      <ImageModal
        isModalOpen={isModalOpen}
        modalSource={modalSource}
        modalText={modalText}
        changeModalOpen={changeModalOpen}
      />
      <Outlet context={handleModalClick} />
    </div>
  )
}

export default Layout
