const IndeedIcon = ({ svgClass }) => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      className={svgClass}
      width='100%'
      height='100%'
      viewBox='0 0 63.235415 63.235416'
      version='1.1'
      id='svg1'
      xmlSpace='preserve'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g transform='translate(-73.40435,-116.88229)'>
        <path d='m 103.55677,175.55365 c -0.82741,-0.41724 -1.3784,-0.95146 -1.842,-1.78594 -0.66146,-1.19062 -0.66146,-1.19062 -0.66146,-11.90625 v -10.71562 l 2.2059,-0.19983 c 1.87304,-0.16968 4.3479,-0.82377 6.19462,-1.63721 0.43708,-0.19253 0.46302,0.45408 0.46302,11.54225 0,11.65507 -0.005,11.75536 -0.59531,12.92674 -0.66441,1.31757 -2.3397,2.37117 -3.77031,2.37117 -0.44766,0 -1.34517,-0.26789 -1.99446,-0.59531 z M 83.905669,147.50781 c 0.868789,-7.389 4.960313,-15.25236 10.37432,-19.93806 5.854961,-5.06734 13.211091,-6.82332 19.198931,-4.58298 3.00539,1.12446 6.4921,4.10936 6.4921,5.55776 0,0.73863 -0.39879,0.63837 -2.34179,-0.58874 -3.53216,-2.23076 -5.80736,-2.89962 -9.82905,-2.88952 -5.36581,0.0135 -10.388542,2.39399 -14.972175,7.09604 -3.276997,3.36165 -5.801308,7.79435 -7.684174,13.49342 -1.026974,3.10845 -1.462481,3.7599 -1.238162,1.85208 z m 19.099681,-1.91894 c -1.34309,-0.431 -3.122086,-1.98766 -3.824998,-3.34694 -0.688911,-1.33221 -0.805878,-3.9484 -0.242483,-5.42363 1.373841,-3.59734 5.552411,-5.34633 9.063031,-3.79341 2.33837,1.03437 3.72799,2.97077 3.95883,5.51651 0.26109,2.87937 -1.22397,5.56118 -3.74092,6.75555 -1.36565,0.64804 -3.69694,0.77858 -5.21346,0.29192 z' />
      </g>
    </svg>
  )
}

export default IndeedIcon
