import { Route, Routes } from 'react-router-dom'
import './App_v2-1.scss'
import Layout from './components/Layout/layout_v2-1'
import Home from './components/Pages/Home/home_v2-1'
import PortfolioPage from './components/Pages/PortfolioPages/portfolio-page_v2-1'
import { portfolioPageData } from './components/AddOns/Data/portfolio-page-data_v2-1'

function App() {
  const portfolioPages = portfolioPageData.map((d, idx) => {
    return (
      <Route
        key={idx}
        path={d.routePath}
        element={
          <PortfolioPage
            projectTitle={d.projectTitle}
            teamInfo={d.teamInfo}
            roleInfo={d.roleInfo}
            dateCompleted={d.dateCompleted}
            paragraph1={d.paragraph1}
            paragraph2={d.paragraph2}
            paragraph3={d.paragraph3}
            image1={d.image1}
            image2={d.image2}
            image3={d.image3}
            image1Mini={d.image1Mini}
            image2Mini={d.image2Mini}
            image3Mini={d.image3Mini}
            image1Alt={d.image1Alt}
            image2Alt={d.image2Alt}
            image3Alt={d.image3Alt}
            image1Fig={d.image1Fig}
            image2Fig={d.image2Fig}
            image3Fig={d.image3Fig}
            techsData={d.techsData}
            circleMod={d.circleMod}
            circleClass={d.circleClass}
            repoPath={d.repoPath}
          />
        }
      />
    )
  })

  return (
    <>
      <Routes>
        <Route
          path='/'
          element={<Layout />}
        >
          <Route
            index
            element={<Home />}
          />
          {portfolioPages}
        </Route>
      </Routes>
    </>
  )
}

export default App
