import './svg-button_v2-1.scss'

const SVGButton = ({
  buttonType = 'button',
  buttonIcon,
  buttonLabel,
  clickFunction = null,
  tx,
  ty,
  rdeg,
  scale,
}) => {
  const transformStyle = {
    '--tx': `${tx}rem`,
    '--ty': `${ty}rem`,
    '--rdeg': `${rdeg}deg`,
    '--scale': `${scale}`,
  }

  return (
    <button
      type={buttonType}
      onClick={clickFunction}
      aria-label={buttonLabel}
      className='button-link'
      style={transformStyle}
    >
      {buttonIcon}
      <span className='button-label'>{buttonLabel}</span>
    </button>
  )
}

export default SVGButton
