import './home_v2-1.scss'
import HeroSection from './HeroSection/hero-section_v2-1'
import CircleList from '../../AddOns/CircleList/circle-list_v2-1'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGitlab, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import IndeedIcon from '../../AddOns/SVGs/indeed-icon_v2-1'
import { Link, useOutletContext } from 'react-router-dom'
import { motion } from 'framer-motion'
import SVGALink from '../../AddOns/AnimatedButtonsLinks/svg-alink_v2-1'
import { homeSkillsListData } from '../../AddOns/Data/home-skills-list-data_v2-1'
import { portfolioCardData } from '../../AddOns/Data/portfolio-card-data_v2-1'
import CardCarousel from '../../AddOns/CardCarousel/card-carousel_v2-1'
import ContactForm from '../../AddOns/ContactForm/contact-form_v2-1'
import { faFileLines } from '@fortawesome/free-solid-svg-icons'
import SVGRLink from '../../AddOns/AnimatedButtonsLinks/svg-rlink_v2-1'

const Home = () => {
  // Animations

  const draw = {
    hidden: { pathLength: 0 },
    visible: {
      pathLength: 1,
      transition: {
        duration: 0.25,
        ease: 'easeIn',
      },
    },
  }

  const shift = {
    hidden: { y: 0 },
    visible: {
      y: '5px',
      transition: {
        duration: 0.25,
      },
    },
  }

  // Context for image modal
  const handleImageClick = useOutletContext()

  return (
    <div
      id='home'
      className='anchor page home-page'
    >
      <HeroSection />
      <div
        id='about'
        className='anchor container about-container'
      >
        <div className='content about-content'>
          <div className='content-grid about-grid'>
            <div className='content-grid-left about-text'>
              <h1 className='title about-title'>My Story</h1>
              <div className='about-paragraph-container no-transition'>
                <p className='about-paragraph'>
                  I've loved tinkering with computers since I was a kid, and the
                  love has yet to stop. I've also spent my life highly involved
                  in artistic extra-curriculars like theater, choir, and art.
                  These experiences have given me a unique range of skills that
                  make me an excellent creator.
                </p>
                <p className='about-paragraph'>
                  I completed my bachelor's in Computer Science at the South
                  Dakota School of Mines & Technology. Throughout my studies, I
                  developed a particular fondness towards GUI development,
                  front-end development, and game development. The desire to
                  learn more about these topics inspired many of the projects
                  you can find below!
                </p>
                <span className='arrow-container'>
                  <Link
                    aria-label='Link to portfolio section'
                    className='arrow-link'
                    to='/#portfolio'
                  >
                    <motion.svg
                      aria-hidden='true'
                      focusable='false'
                      className='arrow-svg'
                      xmlns='http://www.w3.org/2000/svg'
                      width='100%'
                      height='100%'
                      viewBox='0 0 32 32'
                      version='1.1'
                      initial='hidden'
                      whileHover='visible'
                      variants={shift}
                    >
                      <g>
                        <motion.path
                          className='arrow-circle'
                          d='M 16 0.88 a 15.12 15.12 0 1 0 0 30.24 a 15.12 15.12 0 1 0 0 -30.24 l -0.2 0.0025 Z'
                          variants={draw}
                        ></motion.path>
                        <path
                          className='arrow-lines'
                          d='M22.21 16L16.14 22.07l-6.07 -6.07M16.14 8.09v 13.98'
                        ></path>
                      </g>
                    </motion.svg>
                  </Link>
                </span>
              </div>
              <div className='socials-container'>
                <h2 className='socials-header'>
                  Here's Where You Can Find Me:
                </h2>
                <ul className='socials-list'>
                  <li className='socials-item'>
                    <SVGALink
                      buttonURL='https://www.linkedin.com/in/jessica-roberts-46506a168/'
                      buttonIcon={
                        <FontAwesomeIcon
                          className='button-icon'
                          icon={faLinkedin}
                        />
                      }
                      buttonLabel='LinkedIn'
                      tx={-15}
                      ty={10}
                      rdeg={60}
                      scale={25}
                    />
                  </li>
                  <li className='socials-item'>
                    <SVGALink
                      buttonURL='https://profile.indeed.com/p/jessicar-5khrjnf'
                      buttonIcon={<IndeedIcon svgClass='button-icon' />}
                      buttonLabel='Indeed'
                      tx={-5}
                      ty={4}
                      rdeg={60}
                      scale={25}
                    />
                  </li>
                  <li className='socials-item'>
                    <SVGALink
                      buttonURL='https://gitlab.com/jessica_roberts5257'
                      buttonIcon={
                        <FontAwesomeIcon
                          className='button-icon'
                          icon={faGitlab}
                        />
                      }
                      buttonLabel='GitLab'
                      tx={0}
                      ty={-3}
                      rdeg={60}
                      scale={25}
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className='content-grid-right skills'>
              <h1 className='title skills-title'>Skills</h1>
              <div className='skills-text no-transition'>
                <p>
                  These are some languages and tools I'm most comfortable with
                  right now. For more details about my experience, download my
                  resume below.
                </p>
              </div>
              <div className='skills-list'>
                <div className='skills-circle-center-container'>
                  <SVGRLink
                    buttonURL='/files/jessica_roberts_resume_v2-1.pdf'
                    isDownload={true}
                    downloadName='Jessica Roberts Resume'
                    buttonIcon={
                      <FontAwesomeIcon
                        className='button-icon'
                        icon={faFileLines}
                      />
                    }
                    buttonLabel='Resume'
                    tx={-8}
                    ty={1}
                    rdeg={60}
                    scale={30}
                  />
                  <p className='skills-list-text'>
                    Click the icons for more information!
                  </p>
                </div>
                <CircleList
                  listData={homeSkillsListData}
                  sizeModifier={2.5}
                  iconColor='#734bff'
                  labelColor='#382086'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id='portfolio'
        className='anchor container portfolio-container'
      >
        <div className='content portfolio-content'>
          <h1 className='title portfolio-title'>Portfolio</h1>
          <div className='portfolio-carousel-wrapper'>
            <CardCarousel
              slideData={portfolioCardData}
              handleImageClick={handleImageClick}
            />
          </div>
        </div>
      </div>
      <div
        id='contact'
        className='anchor container contact-container'
      >
        <div className='content contact-content'>
          <h1 className='title contact-title'>Contact Me</h1>
          <div className='contact-form-wrapper'>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
