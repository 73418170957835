import { motion } from 'framer-motion'

const BouncingLink = ({ linkClass = '', children }) => {
  return (
    <motion.span
      className={linkClass}
      initial={{
        y: '0rem',
      }}
      whileHover={{
        y: ['0rem', '-0.5rem'],
        transition: {
          repeat: Infinity,
          repeatType: 'reverse',
          type: 'tween',
          ease: 'linear',
          duration: 0.3,
        },
      }}
      transition={{
        type: 'tween',
        ease: 'linear',
      }}
    >
      {children}
    </motion.span>
  )
}

export default BouncingLink
