import { stagger, useAnimate } from 'framer-motion'
import { useEffect } from 'react'

export function useLaptopAnimation() {
  const [scope, animate] = useAnimate()

  useEffect(() => {
    const laptopSequence = [
      ['.frame-1', { opacity: 1 }, { duration: 0.1 }],
      'keyframe-1',
      ['.frame-2', { opacity: 1 }, { duration: 0.1, at: 'keyframe-1' }],
      'keyframe-2',
      ['.frame-1', { opacity: 0 }, { duration: 0.2, at: 'keyframe-1' }],
      ['.frame-3', { opacity: 1 }, { duration: 0.1, at: 'keyframe-2' }],
      'keyframe-3',
      ['.frame-2', { opacity: 0 }, { duration: 0.2, at: 'keyframe-2' }],
      ['.frame-4', { opacity: 1 }, { duration: 0.1, at: 'keyframe-3' }],
      'keyframe-4',
      ['.frame-3', { opacity: 0 }, { duration: 0.2, at: 'keyframe-3' }],
      ['.frame-5', { opacity: 1 }, { duration: 0.1, at: 'keyframe-4' }],
      ['.frame-4', { opacity: 0 }, { duration: 0.2, at: 'keyframe-4' }],
      ['.intro-laptop-container', { opacity: [0, 1] }, { duration: 0.5 }],
      'header-1',
      [
        '.letter-group-1',
        { opacity: [0, 1], y: [25, 0] },
        { duration: 0.15, delay: stagger(0.07) },
      ],
      [
        '.letter-group-2',
        { opacity: [0, 1], y: [25, 0] },
        { duration: 0.15, delay: stagger(0.07, { startDelay: 0.35 }) },
      ],
      'col-bg',
      [
        '.path-1',
        { pathLength: [0, 1] },
        { duration: 1, ease: 'easeInOut', at: 'header-1' },
      ],
      'header-2',
      'col-1',
      [
        '.letter-group-3',
        { opacity: [0, 1], y: [25, 0] },
        { duration: 0.15, delay: stagger(0.075) },
      ],
      'col-2',
      [
        '.letter-group-4',
        { opacity: [0, 1], y: [25, 0] },
        { duration: 0.15, delay: stagger(0.075) },
      ],
      [
        '.path-2',
        { pathLength: [0, 1] },
        { duration: 1.25, ease: 'easeInOut', at: 'header-2' },
      ],
      [
        '.cols-background',
        { opacity: [0, 1], x: [30, 0] },
        { duration: 1, at: 'col-bg' },
      ],
      [
        '.intro-cols-left',
        { opacity: [0, 1], y: [20, 0] },
        { duration: 0.75, at: 'col-1', delay: 0.1 },
      ],
      [
        '.intro-cols-right',
        { opacity: [0, 1], y: [20, 0] },
        { duration: 0.75, at: 'col-2' },
      ],
    ]

    if (window.sessionStorage.getItem('animationComplete') === null) {
      animate(laptopSequence).then(() => {
        window.sessionStorage.setItem('animationComplete', 'true')
      })
    } else {
      animate(laptopSequence).complete()
    }
  }, [animate])

  return scope
}
