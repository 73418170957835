import { Link } from 'react-router-dom'
import './svg-button_v2-1.scss'

const SVGRLink = ({
  buttonURL,
  isDownload = false,
  downloadName = '',
  buttonIcon,
  buttonLabel,
  tx,
  ty,
  rdeg,
  scale,
}) => {
  const transformStyle = {
    '--tx': `${tx}rem`,
    '--ty': `${ty}rem`,
    '--rdeg': `${rdeg}deg`,
    '--scale': `${scale}`,
  }

  return (
    <Link
      aria-label={`Link to ${buttonLabel}`}
      className='button-link'
      target='_blank'
      to={buttonURL}
      download={isDownload ? downloadName : false}
      style={transformStyle}
    >
      {buttonIcon}
      <span className='button-label'>{buttonLabel}</span>
    </Link>
  )
}

export default SVGRLink
