import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CIcon from '../SVGs/c-icon_v2-1'
import { faJava, faPython } from '@fortawesome/free-brands-svg-icons'
import CppIcon from '../SVGs/cpp-icon_v2-1'

export const cryptoTechsData = [
  {
    url: 'https://www.w3schools.com/c/c_intro.php?external_link=true',
    icon: <CIcon svgClass='item-icon' />,
    label: 'C',
    labelFontSize: '16pt',
  },
  {
    url: 'https://www.python.org/doc/essays/blurb/',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faPython}
      />
    ),
    label: 'Python',
    labelFontSize: '16pt',
  },
  {
    url: 'https://www.w3schools.com/cpp/cpp_intro.asp',
    icon: <CppIcon svgClass='item-icon' />,
    label: 'C++',
    labelFontSize: '16pt',
  },
  {
    url: 'https://aws.amazon.com/what-is/java/',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faJava}
      />
    ),
    label: 'Java',
    labelFontSize: '16pt',
  },
]
