import BakingImage1 from '../../../assets/images/pages_v2-1/baking_v2-1/models.png'
import BakingImage2 from '../../../assets/images/pages_v2-1/baking_v2-1/milk_pour.png'
import BakingImage3 from '../../../assets/images/pages_v2-1/baking_v2-1/confetti.png'
import BakingImage1Mini from '../../../assets/images/pages_v2-1/baking_v2-1/models_mini.png'
import BakingImage2Mini from '../../../assets/images/pages_v2-1/baking_v2-1/milk_pour_mini.png'
import BakingImage3Mini from '../../../assets/images/pages_v2-1/baking_v2-1/confetti_mini.png'
import { bakingSimTechsData } from './baking-sim-techs-data_v2-1'
import BouncingLink from '../AnimatedButtonsLinks/bouncing-link_v2-1'
import CryptoImage1 from '../../../assets/images/pages_v2-1/crypto_v2-1/adfgx.png'
import CryptoImage2 from '../../../assets/images/pages_v2-1/crypto_v2-1/freq_analysis.png'
import CryptoImage3 from '../../../assets/images/pages_v2-1/crypto_v2-1/rsa.png'
import CryptoImage1Mini from '../../../assets/images/pages_v2-1/crypto_v2-1/adfgx_mini.png'
import CryptoImage2Mini from '../../../assets/images/pages_v2-1/crypto_v2-1/freq_analysis_mini.png'
import CryptoImage3Mini from '../../../assets/images/pages_v2-1/crypto_v2-1/rsa_mini.png'
import { cryptoTechsData } from './crypto-techs-data_v2-1'
import FaceSwapImage1 from '../../../assets/images/pages_v2-1/face_swap_v2-1/h_matrix.png'
import FaceSwapImage2 from '../../../assets/images/pages_v2-1/face_swap_v2-1/face_detected.png'
import FaceSwapImage3 from '../../../assets/images/pages_v2-1/face_swap_v2-1/face_swapped.png'
import FaceSwapImage1Mini from '../../../assets/images/pages_v2-1/face_swap_v2-1/h_matrix_mini.png'
import FaceSwapImage2Mini from '../../../assets/images/pages_v2-1/face_swap_v2-1/face_detected_mini.png'
import FaceSwapImage3Mini from '../../../assets/images/pages_v2-1/face_swap_v2-1/face_swapped_mini.png'
import { faceSwapTechsData } from './face-swap-techs-data_v2-1'
import GreenhouseImage1 from '../../../assets/images/pages_v2-1/greenhouse_v2-1/dashboard.png'
import GreenhouseImage2 from '../../../assets/images/pages_v2-1/greenhouse_v2-1/flow_chart.png'
import GreenhouseImage3 from '../../../assets/images/pages_v2-1/greenhouse_v2-1/config.png'
import GreenhouseImage1Mini from '../../../assets/images/pages_v2-1/greenhouse_v2-1/dashboard_mini.png'
import GreenhouseImage2Mini from '../../../assets/images/pages_v2-1/greenhouse_v2-1/flow_chart_mini.png'
import GreenhouseImage3Mini from '../../../assets/images/pages_v2-1/greenhouse_v2-1/config_mini.png'
import { greenhouseTechsData } from './greenhouse-techs-data_v2-1'
import WaterfrontImage1 from '../../../assets/images/pages_v2-1/waterfront_v2-1/demo.gif'
import WaterfrontImage2 from '../../../assets/images/pages_v2-1/waterfront_v2-1/sim_table.png'
import WaterfrontImage3 from '../../../assets/images/pages_v2-1/waterfront_v2-1/home_page.png'
import WaterfrontImage1Mini from '../../../assets/images/pages_v2-1/waterfront_v2-1/demo_mini.gif'
import WaterfrontImage2Mini from '../../../assets/images/pages_v2-1/waterfront_v2-1/sim_table_mini.png'
import WaterfrontImage3Mini from '../../../assets/images/pages_v2-1/waterfront_v2-1/home_page_mini.png'
import { waterfrontTechsData } from './waterfront-techs-data_v2-1'

export const portfolioPageData = [
  {
    routePath: '/baking-sim',
    projectTitle: 'VR Baking Simulator',
    teamInfo: 'Thom Lippincott, Sam Pfeiffer',
    roleInfo: 'Co-developer, 3D Modeler',
    dateCompleted: 'May 2023',
    paragraph1:
      'Created a virtual reality baking simulator game designed for the Vive Cosmo Elite. The game was created using the Unity game engine in C#. The game world is a 15 x 10m kitchen that contains a countertop, pantry, oven, refrigerator, and table. There are also ingredients (salt, yeast, flour, and milk) and tools (spoon, bowl, and two baking sheets) inside the room. When starting the game, the player will appear between the counter and the pantry. The recipe instructions are displayed on the east wall.',
    paragraph2:
      "To continue the level, all ingredients must be added to the bowl. Then, the spoon object must touch the bowl. This will create a dough object. If the dough is dropped on the floor, a failure sound effect will play, but the level can still continue. When a baking sheet object and the dough object are placed in the oven, a success sound effect and confetti animation will play, and the dough will turn into bread. The game can be played in VR, with keyboard and mouse, or with a playstation controller using Unity's input systems.",
    paragraph3: [
      "All of the game's assets were created by my team except for the sound effects and background music which are credited to ",
      <BouncingLink
        key='baking-sim-link'
        linkClass='project-credit-link-container'
      >
        <a
          className='project-credit-link'
          href='https://pixabay.com/'
          target='_blank'
          rel='noreferrer'
        >
          Pixabay.
        </a>
      </BouncingLink>,
      "I created the flour, milk, salt, yeast, dough, bread, and bowl models using Blender. Each ingredient model has an opened and closed version. I created textures for each of these models (excluding the bowl) by drawing the design and using Blender's UV mapping to map the design onto the models. I also created the confetti animation using a Unity particle system and wrote the logic for most of the gameplay events (creating dough when all ingredients have been added to the bowl, playing sound effects after certain events, etc).",
    ],
    image1: BakingImage1,
    image2: BakingImage2,
    image3: BakingImage3,
    image1Mini: BakingImage1Mini,
    image2Mini: BakingImage2Mini,
    image3Mini: BakingImage3Mini,
    image1Alt: 'Open and Closed Models',
    image2Alt: 'Milk Pour Physics Example',
    image3Alt: 'Confetti Animation',
    image1Fig: 'Open and closed versions of all ingredient models',
    image2Fig: 'Pouring the milk ingredient into the bowl',
    image3Fig: 'Confetti animation playing upon successfully baking bread',
    techsData: bakingSimTechsData,
    circleMod: 1.5,
    circleClass: 'gapped-circle',
    repoPath: 'https://gitlab.com/jessica_roberts5257/vr-baking-simulator',
  },
  {
    routePath: '/crypto',
    projectTitle: 'Cryptography Portfolio',
    teamInfo: 'None',
    roleInfo: 'Sole Developer',
    dateCompleted: 'December 2021',
    paragraph1:
      "This portfolio contains a collection of programs dealing with cryptographic algorithms and ciphers. Some of the programs are simple encoders and decoders in that they can only decode ciphertext (encrypted text) if the user knows exactly how the text was encrypted. The following programs fall under this category: the ADFGX cipher, the Affine cipher, the DES (Data Encryption Standard), the simplified DES, the Playfair cipher, the RSA, and the Vigenere cipher. The second category of program in this collection is of 'attack' algorithms. An attack in cryptography refers to the decryption or partial decryption of ciphertext when the 'key' or method for encryption is not known. Instead, this method requires knowledge of how the encryption algorithm works and how it or its patterns can be exploited. The programs that fall under this category are the following: the Affine attack, the DES differential, the frequency analysis, and the substitution cipher crack.",
    paragraph2:
      "The Affine and DES differential are 'known plaintext' attacks which means that they require both the ciphertext and at least a portion of the plaintext (unencrypted text) that was used to create that ciphertext. The frequency analysis and substitution cipher crack are 'ciphertext only' attacks which means that they can decrypt the ciphertext without knowledge of the plaintext, keys, etc. These specific attacks do, however, require that the original language of the plaintext is known, and also the ciphertext must have been encrypted using a substitution cipher.",
    paragraph3:
      'The last program in this collection is a custom python library that contains mathematical functions commonly needed for cryptographic algorithms. The following functions are available from the library: greatest common divisor, extended euclidean algorithm, modular multiplicative inverse, finding the squares of an integer mod some number n, determine if a number is prime, generate a random prime, a mod power function, and a factorization function that can use the Fermat, Pollard Rho, or Pollard P-1 method of factorization. This library is used in the Affine attack, the Affine cipher, and the RSA. All of the programs have a terminal-only interface.',
    image1: CryptoImage1,
    image2: CryptoImage2,
    image3: CryptoImage3,
    image1Mini: CryptoImage1Mini,
    image2Mini: CryptoImage2Mini,
    image3Mini: CryptoImage3Mini,
    image1Alt: 'ADFGX Cipher Example',
    image2Alt: 'Frequency Analysis Example',
    image3Alt: 'RSA Example',
    image1Fig:
      'Console menu for encrypting and decrypting text using the ADFGX cipher',
    image2Fig: 'Running a frequency analysis attack on a file of ciphertext',
    image3Fig: 'Encrypting and decrypting integer data using the RSA algorithm',
    techsData: cryptoTechsData,
    circleMod: 1.5,
    circleClass: 'gapped-circle',
    repoPath: 'https://gitlab.com/jessica_roberts5257/cryptography-portfolio',
  },
  {
    routePath: '/face-swap',
    projectTitle: 'Face Swap Desktop Application',
    teamInfo: 'Sam Pfeiffer',
    roleInfo: 'Co-developer, GUI developer',
    dateCompleted: 'November 2022',
    paragraph1:
      "Created an application that would take in a picture from the user of a face or multiple faces, and would allow them to replace all of the faces in that picture with a face from a different picture. These pictures will be referred to as the base image, the image with faces to be replaced, and the pasting image, the image that will replace the face from the base image. This was done by using a homography matrix transformation and Open CV's Haar Cascade face detection algorithm.",
    paragraph2:
      'A planar (two-dimensional) homography matrix is a 3x3 matrix whose values relate the transformation between two planes. This is valuable to image processing because, by using this matrix, one can calculate where a point (x-y coordinate) in one image would lie in another image. The most common application for this is to stitch two images of the same thing, taken from different angles, into one panorama.',
    paragraph3:
      "In this case, we used it to match the scale and location of faces from separate images. The Haar Cascade face detection algorithm signifies a detected face with a bounding box. This algorithm is used on both the base image and the pasting image to determine the scale and placement of each face within its own image. The corners of each of these bounding boxes can then be used as 'features' or anchor points within the image. The corresponding corners (top left to top left, etc) of the boxes are used to calculate the homography matrix. Then, each pixel coordinate of the pasting image is operated on by the homography matrix to receive the coordinate of where that pixel belongs in the base image. This is done until the entire pasting image exists within the base image. The program assumes that the pasting image has already been 'green-screened' in that everything except for the face has been replaced with pure RGB(0, 255, 0) green.",
    image1: FaceSwapImage1,
    image2: FaceSwapImage2,
    image3: FaceSwapImage3,
    image1Mini: FaceSwapImage1Mini,
    image2Mini: FaceSwapImage2Mini,
    image3Mini: FaceSwapImage3Mini,
    image1Alt: 'Homography Matrix Example',
    image2Alt: 'Face Detection Box Example',
    image3Alt: "WWE superstar Dwayne 'The Rock' Johnson",
    image1Fig:
      'Example of using a homography matrix to calculate where feature points from one image line up in a second image',
    image2Fig: 'Using face detection on a picture of me in a park',
    image3Fig: "WWE superstar Dwayne 'The Rock' Johnson in a park",
    techsData: faceSwapTechsData,
    circleMod: 1.5,
    circleClass: 'gapped-circle',
    repoPath: 'https://gitlab.com/jessica_roberts5257/face-swap',
  },
  {
    routePath: '/greenhouse',
    projectTitle: 'Greenhouse Remote Monitor',
    teamInfo: 'Sam Pfeiffer, Sam Donovan, Tim Ford (Sponsor)',
    roleInfo: 'Web Interface Developer, Co-documentation Developer',
    dateCompleted: 'May 2022',
    paragraph1:
      "Caring for large quantities of plants can be difficult. It often requires careful adjustment of the environment the plants are kept in, and constant monitoring to ensure that this environment stays within ideal conditions. This is typically done by having a rotation of employees to check on and adjust the environment as needed, or enough free time to do it yourself. However, for greenhouse hobbyists, or smaller businesses, this isn't always feasible. That's why my team worked to create a solution in the form of a remote monitoring system. Existing greenhouse monitoring systems are large, expensive, and have to be professionally installed. So, we created a monitor that is small, inexpensive, and simple to set up and use. Our monitor also has potential to be reactive, so that if conditions fall outside of a specified range, the device will work to fix it, such as by running a heater if temperatures drop, or turning on a light on a cloudy day.",
    paragraph2:
      'Our sponsor started developing a remote monitoring system for his personal greenhouse as a passion project, which was then formalized into our senior design project. For the system to be user-friendly, data needs to be collected, processed and analyzed, and displayed to the user in a digestible way. Additionally, we wanted to make our system as configurable as possible, so that our system could be used to monitor different types of plants and environments. This required a complex system architecture that could flow in two directions. The system starts when the board collects sensor data, tracking the current temperature, humidity, light and soil moisture levels. The data is then displayed to the user via a serial port communication. If any of that data is out of the set bounds, then an error message is displayed to the user. After the data is collected, it is sent to ingestion where it is formatted into JSON packages that contain the data value itself, the date and time of collection, and the device it was sent from.',
    paragraph3:
      "These packages are kept in the 'hot storage' Redis database for easy access. The hot storage is then either sent directly to the API (to use for live data display) or sent to be analyzed. Analysis is used to create interesting figures based on the data, such as the average temperature maintained in the greenhouse within the past month. Analysis is run periodically using a shell script. The analyzed aggregate data is stored in a 'cold storage' Mongo database, and is only retrieved when needed. The API, which was created using FastAPI, can then pull from either hot or cold storage. The website uses the API to access this data and display it in line graphs made with Chart.js. The website home page displays a graph for each sensor type (light, humidity, soil moisture, and temperature) that updates in real time. There is also a page for each sensor type that displays a graph of the aggregate data over different spans of time. The last feature of the website is the configuration page where a user can send parameter boundaries to their device remotely. Our documentation website was created using the static site generator Hugo. Automatic deployment was done using Portainer, Docker, and Gitlab CI/CD.",
    image1: GreenhouseImage1,
    image2: GreenhouseImage2,
    image3: GreenhouseImage3,
    image1Mini: GreenhouseImage1Mini,
    image2Mini: GreenhouseImage2Mini,
    image3Mini: GreenhouseImage3Mini,
    image1Alt: 'Greenhouse Home Page Dashboard',
    image2Alt: 'Project Architecture Flow Chart',
    image3Alt: 'Sensor Configuration Page',
    image1Fig:
      'Home page dashboard showing live, scrolling graphs for all sensors',
    image2Fig: 'Project architecture flow chart',
    image3Fig: 'Configuration page to send environment parameters to deivce',
    techsData: greenhouseTechsData,
    circleMod: 2.5,
    circleClass: 'crowded-circle',
    repoPath: 'https://future_farmers.gitlab.io/documentation/01overview/',
  },
  {
    routePath: '/waterfront',
    projectTitle: 'Waterfront Real Estate Simulator',
    teamInfo: 'None',
    roleInfo: 'Sole Developer',
    dateCompleted: 'April 2022',
    paragraph1:
      'This website simulates a waterfront property planner. Users can add rows of available properties to the waterfront and choose the type of building to place in each lot (house, hotel, or empty). There is also a choice between two preconfigured arrangements: one all hotel row or two mixed rows. The main page contains the core functionality of the application. On this page, is the waterfront simulation area where property layouts can be created.',
    paragraph2:
      "The 'add row' button adds a row of empty properties underneath the previous (closest to water line) row to create up to five rows. 'Delete row' removes the last (furthest from water line) row of buildings, but only if there is more than one row remaining. The 'undo' and 'redo' buttons undo and redo the most recent changes made to the waterfront up to five times, respectively. The dropdowns underneath the main table are used to edit the buildings in the last row. Each box corresponds to the building in the column directly above it. The reset button removes any changes that have been made and creates a single row of empty plots. This page will save all changes to the simulation area when switching pages or reloading the page.",
    paragraph3:
      "In previous versions of the project, there was also a 'save' button on the main page that would allow saving the current state of the simulation area to a file. This file would then appear on a separate file page where it could be downloaded or loaded into the simulation area. This file handling was done in PHP. However, the server where this version was hosted is no longer available, so this functionality does not exist in the current version of the project.",
    image1: WaterfrontImage1,
    image2: WaterfrontImage2,
    image3: WaterfrontImage3,
    image1Mini: WaterfrontImage1Mini,
    image2Mini: WaterfrontImage2Mini,
    image3Mini: WaterfrontImage3Mini,
    image1Alt: 'Undo/redo Demo',
    image2Alt: 'Waterfront Simulation Area',
    image3Alt: 'Website Home Page',
    image1Fig:
      'Demonstration of changing property types, adding/deleting rows, and undo/redo',
    image2Fig:
      'Image of the waterfront property simulation area. The wave design at the top is the water line',
    image3Fig: 'The full home page of the website',
    techsData: waterfrontTechsData,
    circleMod: 1.5,
    circleClass: 'gapped-circle',
    repoPath: 'https://gitlab.com/jessica_roberts5257/waterfront-simulator',
  },
]
