import BakingCardImage from '../../../assets/images/home_v2-1/baking_ss.png'
import CryptoCardImage from '../../../assets/images/home_v2-1/crypto_ss.png'
import FaceSwapCardImage from '../../../assets/images/home_v2-1/face_swap_ss.jpg'
import GreenhouseCardImage from '../../../assets/images/home_v2-1/greenhouse_ss.png'
import WaterfrontCardImage from '../../../assets/images/home_v2-1/waterfront_ss.png'

export const portfolioCardData = [
  {
    image: BakingCardImage,
    imageAlt: 'Baking Simulator Example',
    imagePosition: 'center',
    date: 'may 2023',
    title: 'VR Baking Simulator',
    blurb:
      'A virtual reality baking simulator game created with Unity. Features support for VR, desktop, and playstation control schemes.',
    pageLink: '/baking-sim',
    repoLink: 'https://gitlab.com/jessica_roberts5257/vr-baking-simulator',
  },
  {
    image: CryptoCardImage,
    imageAlt: 'Output of Substitution Cipher Crack',
    imagePosition: 'left',
    date: 'december 2021',
    title: 'Cryptography Portfolio',
    blurb:
      'A collection of programs practicing cryptographic algorithms in multiple languages. Features a self-made cryptomath python library.',
    pageLink: '/crypto',
    repoLink: 'https://gitlab.com/jessica_roberts5257/cryptography-portfolio',
  },
  {
    image: FaceSwapCardImage,
    imageAlt: 'Face Swap Example',
    imagePosition: 'center',
    date: 'november 2022',
    title: 'Face Swap App',
    blurb:
      'A fun application that uses homography matrix transformations and face detection to replace faces from one image with faces from a different image.',
    pageLink: '/face-swap',
    repoLink: 'https://gitlab.com/jessica_roberts5257/face-swap',
  },
  {
    image: GreenhouseCardImage,
    imageAlt: 'Greenhouse Monitor Website Home Page',
    imagePosition: 'center',
    date: 'may 2022',
    title: 'Greenhouse Remote Monitor',
    blurb:
      'A remote sensor to monitor and store the environmental parameters of a greenhouse. Created with a robust architecture and automatic deployment pipeline.',
    pageLink: '/greenhouse',
    repoLink: 'https://future_farmers.gitlab.io/documentation/01overview/',
  },
  {
    image: WaterfrontCardImage,
    imageAlt: 'Waterfront Simulation Home Page',
    imagePosition: 'center',
    date: 'april 2022',
    title: 'Real Estate Sim',
    blurb:
      'A web app that allows the user to create a customizable waterfront property layout. Created as an exercise in the undo/redo system and persistent storage.',
    pageLink: '/waterfront',
    repoLink: 'https://gitlab.com/jessica_roberts5257/waterfront-simulator',
  },
]
