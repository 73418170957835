const FramerMotionIcon = ({ svgClass }) => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      className={svgClass}
      width='100%'
      height='100%'
      viewBox='0 0 28.783767 28.463946'
      version='1.1'
      id='svg1'
      xmlSpace='preserve'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g transform='translate(-90.608116,-134.26802)'>
        <path d='m 102.48646,161.47168 c -4.725865,-1.04668 -8.34041,-4.19834 -9.989553,-8.71027 -0.780575,-2.13559 -0.766371,-6.42419 0.0285,-8.60568 1.92736,-5.28954 6.975329,-8.8849 12.474593,-8.8849 7.14095,0 13.22917,6.08822 13.22917,13.22917 0,5.50794 -3.65858,10.63351 -8.8849,12.44752 -1.76306,0.61195 -5.25603,0.87892 -6.85781,0.52416 z m 0.92604,-8.07689 1.5875,1.57093 1.5875,-1.57093 1.5875,-1.57094 1.5875,1.57094 1.5875,1.57093 v -6.46987 -6.46987 l -3.175,3.16672 -3.175,3.16673 -3.175,-3.16673 -3.174997,-3.16672 v 6.46987 6.46987 l 1.587497,-1.57093 1.5875,-1.57094 z' />
      </g>
    </svg>
  )
}

export default FramerMotionIcon
