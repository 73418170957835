import { motion } from 'framer-motion'

const LeftArrow = ({ circleClass, arrowClass }) => {
  const draw = {
    hidden: { pathLength: 0 },
    visible: {
      pathLength: 1,
    },
  }

  return (
    <svg
      aria-hidden='true'
      focusable='false'
      width='100%'
      height='100%'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <motion.path
        className={circleClass}
        d='M 23 12 c 0 -6 -5 -11 -11 -11 S 1 6 1 12 s 5 11 11 11 s 11 -5 11 -11 l -0.001 -0.25 Z'
        fill='none'
        inherit={true}
        variants={draw}
        transition={{
          type: 'spring',
          duration: 0.3,
          bounce: 0,
        }}
      />
      <motion.path
        className={arrowClass}
        d='m14.5 18-6-6 6-6'
        fill='none'
      />
    </svg>
  )
}

export default LeftArrow
