import './hero-section_v2-1.scss'
import LaptopFrame1 from '../../../AddOns/SVGs/laptop-frame-1_v2-1'
import LaptopFrame2 from '../../../AddOns/SVGs/laptop-frame-2_v2-1'
import LaptopFrame3 from '../../../AddOns/SVGs/laptop-frame-3_v2-1'
import LaptopFrame4 from '../../../AddOns/SVGs/laptop-frame-4_v2-1'
import LaptopFrame5 from '../../../AddOns/SVGs/laptop-frame-5_v2-1'
import { useLaptopAnimation } from '../../../AddOns/LaptopAnimation/laptop-animation_v2-1'

const HeroSection = () => {
  const scope = useLaptopAnimation()

  return (
    <div
      id='home'
      className='anchor container hero-container'
    >
      <div
        className='intro-content'
        ref={scope}
      >
        <LaptopFrame1 />
        <LaptopFrame2 />
        <LaptopFrame3 />
        <LaptopFrame4 />
        <LaptopFrame5 />
      </div>
    </div>
  )
}

export default HeroSection
