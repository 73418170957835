import {
  faCss3Alt,
  faHtml5,
  faJsSquare,
  faPython,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChartJSIcon from '../SVGs/chartjs-icon_v2-1'
import ArduinoIcon from '../SVGs/arduino-icon_v2-1'
import FastAPIIcon from '../SVGs/fast-api-icon_v2-1'
import MongoDBIcon from '../SVGs/mongodb-icon_v2-1'
import RedisIcon from '../SVGs/redis-icon_v2-1'
import HugoIcon from '../SVGs/hugo-icon_v2-1'
import JQueryIcon from '../SVGs/jquery-icon_v2-1'
import DockerIcon from '../SVGs/docker-icon_v2-1'
import PortainerIcon from '../SVGs/portainer-icon_v2-1'
import CppIcon from '../SVGs/cpp-icon_v2-1'

export const greenhouseTechsData = [
  {
    url: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faJsSquare}
      />
    ),
    label: 'JavaScript',
    labelFontSize: '15pt',
  },
  {
    url: 'https://developer.mozilla.org/en-US/docs/Web/CSS',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faCss3Alt}
      />
    ),
    label: 'CSS3',
    labelFontSize: '16pt',
  },
  {
    url: 'https://developer.mozilla.org/en-US/docs/Web/HTML',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faHtml5}
      />
    ),
    label: 'HTML5',
    labelFontSize: '16pt',
  },
  {
    url: 'https://www.chartjs.org/',
    icon: <ChartJSIcon svgClass='item-icon' />,
    label: 'Chart.js',
    labelFontSize: '16pt',
  },
  {
    url: 'https://www.arduino.cc/en/Guide/Introduction',
    icon: <ArduinoIcon svgClass='item-icon' />,
    label: 'Arduino',
    labelFontSize: '16pt',
  },
  {
    url: 'https://fastapi.tiangolo.com/',
    icon: <FastAPIIcon svgClass='item-icon' />,
    label: 'Fast API',
    labelFontSize: '16pt',
  },
  {
    url: 'https://www.mongodb.com/what-is-mongodb',
    icon: <MongoDBIcon svgClass='item-icon' />,
    label: 'Mongo DB',
    labelFontSize: '15pt',
  },
  {
    url: 'https://www.python.org/doc/essays/blurb/',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faPython}
      />
    ),
    label: 'Python',
    labelFontSize: '16pt',
  },
  {
    url: 'https://www.w3schools.com/cpp/cpp_intro.asp',
    icon: <CppIcon svgClass='item-icon' />,
    label: 'C++',
    labelFontSize: '16pt',
  },
  {
    url: 'https://redis.io/docs/about/',
    icon: <RedisIcon svgClass='item-icon' />,
    label: 'Redis',
    labelFontSize: '16pt',
  },
  {
    url: 'https://gohugo.io/about/what-is-hugo/',
    icon: <HugoIcon svgClass='item-icon' />,
    label: 'Hugo',
    labelFontSize: '16pt',
  },
  {
    url: 'https://jquery.com/',
    icon: <JQueryIcon svgClass='item-icon' />,
    label: 'JQuery',
    labelFontSize: '16pt',
  },
  {
    url: 'https://docs.docker.com/get-started/overview/',
    icon: <DockerIcon svgClass='item-icon' />,
    label: 'Docker',
    labelFontSize: '16pt',
  },
  {
    url: 'https://www.portainer.io/why-portainer',
    icon: <PortainerIcon svgClass='item-icon' />,
    label: 'Portainer',
    labelFontSize: '16pt',
  },
]
