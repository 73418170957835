import CircleItem from '../CircleItem/circle-item_v2-1'
import './circle-list_v2-1.scss'

const CircleList = ({ listData, sizeModifier, iconColor, labelColor }) => {
  const circleItems = listData.map((d, idx) => {
    return (
      <div
        className='circle-item'
        key={idx + 1}
        style={{ '--i': `${idx + 1} `, '--sizeMod': `${sizeModifier}` }}
        id='test'
      >
        <CircleItem
          itemURL={d.url}
          itemIcon={d.icon}
          itemLabel={d.label}
          labelFontSize={d.labelFontSize}
          colorStyle={{
            '--iconColor': `${iconColor}`,
            '--labelColor': `${labelColor}`,
          }}
        />
      </div>
    )
  })

  return (
    <div
      className='circle'
      style={{ '--total': `${listData.length}` }}
    >
      {circleItems}
    </div>
  )
}

export default CircleList
