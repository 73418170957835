import React from 'react'
import ReactDOM from 'react-dom/client'
import './index_v2-1.scss'
import App from './App_v2-1'
import { BrowserRouter } from 'react-router-dom'
import ScrollToAnchor from './components/AddOns/ScrollToAnchor/scroll-to-anchor_v2-1'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToAnchor />
      <App />
    </BrowserRouter>
  </React.StrictMode>
)
