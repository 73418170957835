import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './image-modal_v2-1.scss'
import { faClose } from '@fortawesome/free-solid-svg-icons'

export const ImageModal = ({
  isModalOpen,
  modalSource,
  modalText,
  changeModalOpen,
}) => {
  function handleModalClose() {
    changeModalOpen(false)
  }

  return (
    <div
      className={`lightbox ${isModalOpen ? 'show-lightbox' : 'hide-lightbox'}`}
    >
      <div
        aria-label='Close image modal'
        className='menu-icon'
      >
        <FontAwesomeIcon
          icon={faClose}
          size='2x'
          className='close-icon'
          onClick={handleModalClose}
        />
      </div>
      <div className='modal-content'>
        <div className='display-text-container'>
          <p className='modal-text'>{modalText}</p>
        </div>
        <div className='display-image-container'>
          <img
            className='display-image'
            src={modalSource}
            alt={modalText}
          ></img>
        </div>
      </div>
    </div>
  )
}
