import './navbar_v2-1.scss'
import { Link } from 'react-router-dom'
import { useLayoutEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faClose,
  faHome,
  faMoon,
  faSun,
} from '@fortawesome/free-solid-svg-icons'
import OpenRLink from '../AddOns/AnimatedButtonsLinks/open-rlink_v2-1'

const Navbar = ({
  isDark,
  toggleIsDark,
  changeStopScroll,
  changeModalOpen,
}) => {
  const [showNav, setShowNav] = useState(false)

  function handleMenuToggle() {
    setShowNav((prevState) => {
      changeStopScroll(!prevState)

      return !prevState
    })
  }

  function handleMenuOff() {
    setShowNav(false)
    changeStopScroll(false)
    changeModalOpen(false)
  }

  useLayoutEffect(() => {
    function handleResize() {
      if (window.innerWidth > 960) {
        changeStopScroll(false)
      } else if (window.innerWidth <= 960 && showNav === true) {
        changeStopScroll(true)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [showNav, changeStopScroll])

  return (
    <div className='navbar-background'>
      <div className='navbar-container'>
        <div className='navbar-left'>
          {/* Home Button */}
          <Link
            aria-label='Home'
            className='home-icon-container'
            to='/'
            onClick={handleMenuOff}
          >
            <FontAwesomeIcon
              className='home-icon'
              icon={faHome}
              size='2x'
            />
          </Link>
          {/* Dark/Light Mode Switch */}
          <div
            className='mode-switch-container'
            to='/'
          >
            <input
              aria-label='Dark and light mode switch'
              type='checkbox'
              id='mode-switch-checkbox'
              className='mode-switch-checkbox'
              onChange={toggleIsDark}
              checked={isDark}
            />
            <label
              aria-label='Dark and light mode switch'
              htmlFor='mode-switch-checkbox'
              className='checkbox-label'
            >
              <FontAwesomeIcon
                className='dark-icon'
                icon={faMoon}
              />
              <FontAwesomeIcon
                className='light-icon'
                icon={faSun}
              />
              <span className='mode-switch-circle'></span>
            </label>
          </div>
        </div>
        {/* Mobile Menu Icon */}
        <div
          aria-label={
            showNav ? 'Close navigation menu' : 'Open navigation menu'
          }
          className='menu-icon'
        >
          <FontAwesomeIcon
            icon={showNav ? faClose : faBars}
            size='2x'
            className='close-icon'
            onClick={handleMenuToggle}
          />
        </div>
        {/* Navigation Links */}
        <ul className={showNav ? 'mobile-show' : ''}>
          <li className='nav-item'>
            <OpenRLink
              ariaLabel='Link to profile section'
              linkClass='nav-link'
              linkPath='/#about'
              linkContent='PROFILE'
              containerClass='nav-link-container'
              clickFunction={handleMenuOff}
            />
          </li>
          <li className='nav-item'>
            <OpenRLink
              ariaLabel='Link to portfolio section'
              linkClass='nav-link'
              linkPath='/#portfolio'
              linkContent='PORTFOLIO'
              containerClass='nav-link-container'
              clickFunction={handleMenuOff}
            />
          </li>
          <li className='nav-item'>
            <OpenRLink
              ariaLabel='Link to contact section'
              linkClass='nav-link'
              linkPath='/#contact'
              linkContent='CONTACT'
              containerClass='nav-link-container'
              clickFunction={handleMenuOff}
            />
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Navbar
