import {
  faCss3Alt,
  faHtml5,
  faJsSquare,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import JQueryIcon from '../SVGs/jquery-icon_v2-1'
import PHPIcon from '../SVGs/php-icon_v2-1'

export const waterfrontTechsData = [
  {
    url: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faJsSquare}
      />
    ),
    label: 'JavaScript',
    labelFontSize: '15pt',
  },
  {
    url: 'https://developer.mozilla.org/en-US/docs/Web/CSS',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faCss3Alt}
      />
    ),
    label: 'CSS3',
    labelFontSize: '16pt',
  },
  {
    url: 'https://developer.mozilla.org/en-US/docs/Web/HTML',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faHtml5}
      />
    ),
    label: 'HTML5',
    labelFontSize: '16pt',
  },
  {
    url: 'https://jquery.com/',
    icon: <JQueryIcon svgClass='item-icon' />,
    label: 'JQuery',
    labelFontSize: '16pt',
  },
  {
    url: 'https://www.php.net/manual/en/intro-whatis.php',
    icon: <PHPIcon svgClass='item-icon' />,
    label: 'PHP',
    labelFontSize: '16pt',
  },
]
