// SVG Created By Laurent Demailly - http://www.demailly.com/tcl/about/logos.html, Tcl/Tk, https://commons.wikimedia.org/w/index.php?curid=52945179

const TKIcon = ({ svgClass }) => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      className={svgClass}
      width='100%'
      height='100%'
      viewBox='0 0 42.044764 63.575144'
      version='1.1'
      xmlSpace='preserve'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g transform='translate(-83.999677,-116.71243)'>
        <g transform='matrix(0.03527778,0,0,-0.03527778,-4.6671425,297.25529)'>
          <path d='m 3181.37,4009.63 -62.71,-17.1 34.2,82.65 34.2,230.85 -42.75,8.55 51.31,71.25 63.91,240.19 -112.37,-23.58 c 0,0 59.25,108.47 122.13,179.12 l -82.23,63.13 c 0,0 -264.88,-116.68 -193.63,-495.74 l -54.75,9.59 52.51,-397.36 -44.39,-11.41 c 0,0 45.48,-92.8 109.16,-136.8 5.79,-3.99 -79.8,310.66 11.4,624.16 l 8.55,-19.95 c 0,0 -72.67,-334.16 0,-604.21 0,0 3.35,-6.41 39.69,-2.13 0,0 65.77,123.97 65.77,198.79' />
          <path d='m 2714.31,3725.07 h 12.87 v 26.84 c 5.68,0 11.13,0 16.59,0.88 v -27.72 h 19.2 v -5.02 h -19.2 v -66.76 c 0,-3.93 0,-9.82 8.29,-9.82 5.67,0 8.73,3.92 12.22,8.07 l 3.49,-2.4 c -4.14,-8.29 -14.84,-13.31 -23.57,-13.31 -15.71,0 -17.45,8.51 -17.45,18.98 v 65.24 h -12.44 v 5.02' />
          <path d='m 2905.62,3665.72 c -5.02,-15.49 -13.75,-29.02 -36.44,-29.89 -33.82,0 -44.08,28.15 -44.08,43.21 0,38.84 31.2,48.22 43.64,48.22 14.19,0 34.26,-6.55 34.26,-23.35 0,-5.89 -3.71,-11.57 -9.82,-11.57 -5.89,0 -10.25,3.93 -10.25,10.26 0,8.51 6.98,9.6 6.98,12 0,5.89 -13.75,8.29 -18.55,8.29 -20.95,0 -26.4,-15.27 -26.4,-43.85 0,-13.1 3.27,-23.13 4.58,-26.19 3.27,-7.2 10.04,-11.78 21.38,-12.44 12.66,-0.65 24.88,9.6 30.12,25.31 h 4.58' />
          <path d='m 2966,3638.67 v 5.02 h 16.8 v 140.3 H 2966 v 5.02 h 9.38 c 8.08,0 16.58,0.44 24.44,1.96 v -147.28 h 16.81 v -5.02 H 2966' />
          <path d='m 3174.53,3725.07 h 12.87 v 26.84 c 5.67,0 11.13,0 16.58,0.88 v -27.72 h 19.21 v -5.02 h -19.21 v -66.76 c 0,-3.93 0,-9.82 8.3,-9.82 5.67,0 8.72,3.92 12.22,8.07 l 3.48,-2.4 c -4.14,-8.29 -14.83,-13.31 -23.56,-13.31 -15.71,0 -17.46,8.51 -17.46,18.98 v 65.24 h -12.43 v 5.02' />
          <path d='m 3337.47,3720.05 v 5.02 h 35.78 v -5.02 h -13.31 l -30.11,-26.18 41.46,-50.18 h 9.16 v -5.02 H 3339 v 5.02 h 10.91 l -36.45,44.29 36.88,31.42 v 0.65 z m -52.59,-81.38 v 5.02 h 11.35 v 140.3 h -11.35 v 5.02 h 3.93 c 8.07,0 16.58,0.44 24.44,1.96 v -147.28 h 13.09 v -5.02 h -41.46' />
          <path d='m 3095.34,4465.36 c 0,0 -38.46,-171.48 -27.39,-449.52 3.32,-83.4 12.44,-160.06 23.58,-226.54 h 0.06 c 7.24,-58.38 12.09,-79.51 30.98,-149.85 l -1.11,-45.36 c 0,0 -151.25,482.93 -26.12,871.27' />
          <path d='m 3188.19,4614.06 c 0,0 34.01,16.94 82.77,23.1 0,0 53.31,-97.7 -77.87,-264.5 0,0 78.26,174.69 59.8,229.42 0,0 -20.64,12.82 -64.7,11.98' />
          <path d='m 3172.07,4320.8 c 0,0 30.54,-7.08 45.21,4.8 0,0 -28.9,-112.76 -21.32,-174.79 7.57,-62.02 -47.08,-101.73 -47.08,-101.73 0,0 46.55,58.9 31.92,106.88 -10.14,33.25 1.21,118.36 14.01,145.39 0,0 -11.96,1.67 -22.74,19.45' />
          <path d='m 3141.86,4023.7 c 0,0 50.48,6.04 55.78,31.55 0,0 6.18,-152.52 -63.95,-215.37 0,0 53.51,83.47 45.12,169.82 l -36.95,14' />
          <path d='m 2999.86,3960.22 c 0,0 -16.27,-18.24 -65.12,21.98 0,0 15.56,-102.5 112.24,-149.43 0,0 -66.53,50.13 -84.46,104.28 0,0 23.51,6.91 37.34,23.17' />
          <path d='m 2984.28,3999.1 c 0,0 -66.05,167.65 -51.9,394.19 0,0 15.43,-41.03 65,-55.41 0,0 -23.35,-4.78 -37.01,-2.71 0,0 -34.55,-95.63 23.91,-336.07' />
          <path d='m 3261.73,4778.12 c 0,0 -24.75,181.91 -179.61,-2.05 -123.31,-146.49 -108.61,-270.85 -101.05,-340.24 1.94,-17.75 -22.94,185.31 112.16,318.94 135.1,133.63 165.62,31.11 168.5,23.35' />
          <path d='m 3033.63,4933.27 c 0,0 -121.52,34.71 -160.19,-368.05 0,0 -29.84,124.32 47.34,300.13 77.18,175.82 112.85,67.92 112.85,67.92' />
          <path d='m 2882.88,4962.72 c 0,0 -61.98,-93.22 -47.92,-192.51 0,0 -47.01,186.91 47.92,192.51' />
          <path d='m 3338.99,4701.33 c 0,0 73.29,27.03 -31.65,-245.76 0,0 54.31,79.72 88.5,204.96 21.95,80.37 -56.85,40.8 -56.85,40.8' />
          <path d='m 3457.52,4722.58 c 0,0 51.82,-46.9 15.09,-108.94 0,0 92.17,96.84 -15.09,108.94' />
        </g>
      </g>
    </svg>
  )
}

export default TKIcon
