import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAws,
  faCss3Alt,
  faHtml5,
  faJava,
  faJsSquare,
  faPython,
  faReact,
  faUnity,
} from '@fortawesome/free-brands-svg-icons'
import CppIcon from '../SVGs/cpp-icon_v2-1'
import CSharpIcon from '../SVGs/csharp-icon_v2-1'
import FramerMotionIcon from '../SVGs/framer-motion-icon_v2-1'
import GitIcon from '../SVGs/git-icon_v2-1'

export const homeSkillsListData = [
  {
    url: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faJsSquare}
      />
    ),
    label: 'JavaScript',
    labelFontSize: '15pt',
  },
  {
    url: 'https://developer.mozilla.org/en-US/docs/Web/CSS',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faCss3Alt}
      />
    ),
    label: 'CSS3',
    labelFontSize: '16pt',
  },
  {
    url: 'https://developer.mozilla.org/en-US/docs/Web/HTML',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faHtml5}
      />
    ),
    label: 'HTML5',
    labelFontSize: '16pt',
  },
  {
    url: 'https://www.python.org/doc/essays/blurb/',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faPython}
      />
    ),
    label: 'Python',
    labelFontSize: '16pt',
  },
  {
    url: 'https://www.w3schools.com/cpp/cpp_intro.asp',
    icon: <CppIcon svgClass='item-icon' />,
    label: 'C++',
    labelFontSize: '16pt',
  },
  {
    url: 'https://aws.amazon.com/what-is/java/',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faJava}
      />
    ),
    label: 'Java',
    labelFontSize: '16pt',
  },
  {
    url: 'https://learn.microsoft.com/en-us/dotnet/csharp/tour-of-csharp/',
    icon: <CSharpIcon svgClass='item-icon' />,
    label: 'C#',
    labelFontSize: '16pt',
  },
  {
    url: 'https://git-scm.com/book/en/v2/Getting-Started-What-is-Git%3F',
    icon: <GitIcon svgClass='item-icon' />,
    label: 'Git',
    labelFontSize: '16pt',
  },
  {
    url: 'https://www.w3schools.com/whatis/whatis_react.asp',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faReact}
      />
    ),
    label: 'React',
    labelFontSize: '16pt',
  },
  {
    url: 'https://docs.aws.amazon.com/whitepapers/latest/aws-overview/introduction.html',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faAws}
      />
    ),
    label: 'AWS',
    labelFontSize: '16pt',
  },
  {
    url: 'https://www.framer.com/motion/introduction/',
    icon: <FramerMotionIcon svgClass='item-icon' />,
    label: (
      <>
        <tspan
          x='50%'
          y='50%'
          dy='-1rem'
        >
          Framer
        </tspan>
        <tspan
          x='50%'
          y='50%'
          dy='1rem'
        >
          Motion
        </tspan>
      </>
    ),
    labelFontSize: '15pt',
  },
  {
    url: 'https://en.wikipedia.org/wiki/Unity_(game_engine)',
    icon: (
      <FontAwesomeIcon
        className='item-icon'
        icon={faUnity}
      />
    ),
    label: 'Unity',
    labelFontSize: '16pt',
  },
]
