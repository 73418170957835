import './card-carousel_v2-1.scss'
import { register } from 'swiper/element/bundle'
import { useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import ClosedRLink from '../AnimatedButtonsLinks/closed-rlink_v2-1'
import ClosedALink from '../AnimatedButtonsLinks/closed-alink_v2-1'
import RightArrow from '../SVGs/right-arrow_v2-1'
import LeftArrow from '../SVGs/left-arrow_v2-1'

const CardCarousel = ({ slideData, handleImageClick }) => {
  const swiperRef = useRef(null)

  const shiftRight = {
    hidden: { x: 0 },
    visible: {
      x: '5px',
      transition: {
        duration: 0.3,
      },
    },
  }

  const shiftLeft = {
    hidden: { x: 0 },
    visible: {
      x: '-5px',
      transition: {
        duration: 0.3,
      },
    },
  }

  useEffect(() => {
    register()

    const swiperParams = {
      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
      },
      slidesPerGroup: 1,
      navigation: {
        prevEl: '.prev-arrow',
        nextEl: '.next-arrow',
      },
      loop: true,
      loopAdditionalSlides: 1,
      centeredSlides: true,
      speed: 500,
      autoHeight: true,
      effect: 'coverflow',
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 2.5,
        slideShadows: false,
      },
      breakpoints: {
        960: {
          slidesPerView: 1.5,
        },
        0: {
          slidesPerView: 1,
        },
      },
    }

    Object.assign(swiperRef.current, swiperParams)

    swiperRef.current.initialize()
  }, [])

  const carouselSlides = slideData.map((d, idx) => {
    return (
      <swiper-slide key={idx}>
        <div className='card-container'>
          <div className='card-disabler'></div>
          <div className='card-left-shadow'></div>
          <div className='card-right-shadow'></div>
          <div className='card-background'></div>
          <div className='card-image-shadow-wrapper'>
            <div className='card-image-wrapper'>
              <img
                className='card-image'
                src={d.image}
                alt={d.imageAlt}
                style={{ objectPosition: `${d.imagePosition}` }}
                onClick={(e) => handleImageClick(e, d.image)}
              ></img>
            </div>
          </div>
          <div className='card-text-wrapper'>
            <div className='card-text'>
              <p className='card-date'>{d.date}</p>
              <h2 className='card-title'>{d.title}</h2>
              <p className='card-blurb'>{d.blurb}</p>
            </div>
            <div className='card-links-wrapper'>
              <ClosedRLink
                ariaLabel={`Link to ${d.title} information page`}
                linkClass='card-link page-link'
                linkPath={d.pageLink}
                linkContent='Read More'
                borderColor='#6b7ede'
                bgColor='#f4f7ff'
              />
              <ClosedALink
                ariaLabel={`Link to ${d.title} repository`}
                linkClass='card-link repo-link'
                linkPath={d.repoLink}
                targetStyle='_blank'
                linkContent='Visit Repo'
                borderColor='#6b7ede'
                bgColor='#f4f7ff'
              />
            </div>
          </div>
        </div>
      </swiper-slide>
    )
  })

  return (
    <div className='no-transition carousel-container'>
      <swiper-container
        init='false'
        ref={swiperRef}
      >
        {carouselSlides}
      </swiper-container>
      <div className='nav-arrow-wrapper'>
        <motion.div
          className='nav-arrow prev-arrow'
          initial='hidden'
          whileHover='visible'
          variants={shiftLeft}
        >
          <LeftArrow
            circleClass='nav-svg-circle'
            arrowClass='nav-svg-arrow'
          />
        </motion.div>
        <motion.div
          className='nav-arrow next-arrow'
          initial='hidden'
          whileHover='visible'
          variants={shiftRight}
        >
          <RightArrow
            circleClass='nav-svg-circle'
            arrowClass='nav-svg-arrow'
          />
        </motion.div>
      </div>
    </div>
  )
}

export default CardCarousel
